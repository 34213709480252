<!--
 * @Description: 意见反馈
 * @Author: 前胡
 * @LastEditors: 白青
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-05-28 10:03:50
 -->
 <template>
  <div class="account-modal">
    <a-drawer
      title="查看"
      width="850px"
      placement="right"
      @close="handleHideThisModal"
      :visible="homePreviewDrawerVisible"
    >
    <div>页面地址：{{pageLink}}</div>
      <div>页面状态：<a-tag v-if="status===0">未发布</a-tag>
        <a-tag color="green" v-if="status===1">已发布</a-tag>
        <a-tag color="cyan" v-if="status===2">已下线</a-tag></div>
    <div class="iframe-auto">
       <iframe :src="src" frameborder="0" width="380" height="600"></iframe>
       </div>
    </a-drawer>
  </div>
</template>
<script>
import {ossURL} from '@/config/index'
export default {
  name: "DrawerH5Preview",
  components: {
  },
  props: {
    topic: {
      type: Object
    }
  },
  data() {
    return {
      homePreviewDrawerVisible: true,
      src: `https://static.zheyi360.com/tempTopic/topic_${this.topic.uid}.html?inPreview=1`,
      pageLink: `${ossURL}/topic/topic_${this.topic.uid}.html`,
      status: this.topic.status
    };
  },
  methods: {
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
  },

};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.iframe-auto {
  width: 380px;
  margin: 0 auto 20px;
}
.n-table {
  th,
  td {
    font-weight: normal;
    padding: 5px;
  }
  th {
    text-align: right;
  }
}
.pic-box {
  img {
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>
