<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-03 17:30:54
 * @LastEditTime: 2020-07-03 10:16:04
 * @Description: 意见反馈
 -->
<template>
  <div id="specialManage">
    <TopicSearchBar
      v-auth="'samples_manage_apply_list'"
      @callback="topicSearchCallback"
      :searchParams="searchParams"
      :loading="listLoading"
    />
    <div class="ptb-10">
      <a-button type="primary" @click="handleGoTopicMaker">专题制作</a-button>
    </div>

    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'operate_manage_projects_page'"
      :rowKey="record => record.id"
      :columns="feedbackColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无专题' }"
      @change="handleTableChange"
    >
      <template slot="status" slot-scope="record">
        <a-tag v-if="record.status===0">未发布</a-tag>
        <a-tag color="green" v-if="record.status===1">已发布</a-tag>
        <a-tag color="cyan" v-if="record.status===2">已下线</a-tag>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <a-button size="small" class="mr-10" @click="handleShowTopicPreview(index)" :loading="record.isStartShowPreviewTopic">查看</a-button>
        <a-button size="small" class="mr-10" @click="handleEditTopic(record.id)">编辑</a-button>
        <a-button size="small" v-if="record.status !== 1" class="mr-10" @click="handlePubTopic(record)">发布</a-button>
        <a-button size="small" v-if="record.status === 1" class="mr-10" @click="handleDownTopic(record)">下线</a-button>
      </template>
    </a-table>
    <!--查看账号-->
    <DrawerH5Preview
      :topic="currentTopic"
      v-if="h5PreviewVisiable"
      @callback="hideFeedbackModal"
    />
  </div>
</template>
<script>
import { getTopics } from "@/service/topic";
import DrawerH5Preview from "@/components/admin/operate/drawer-h5-preview";
import { checkPer } from "@/utils/index";
import TopicSearchBar from "@/components/admin/operate/topic-search-bar";
import { patchTopic,previewTopic } from "@/service/topic";
// 寄样申请列表
const feedbackColumns = [
  {
    title: "专题ID",
    fixed: 'left',
    dataIndex: "id",
    align: "left",
    width: 160
  },
  {
    title: "页面标题",
    dataIndex: "page_title",
    align: "left",
    width: 400
  },
  {
    title: "创建人",
    dataIndex: "add_by__username",
    align: "center",
    width: 160
  },
  {
    title: "更新人",
    dataIndex: "modified_by__username",
    align: "center",
    width: 160
  },
  {
    title: "状态",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "status" }
  },
  {
    title: "最后编辑",
    dataIndex: "update_time",
    align: "center",
    width: 160
  },
  {
    title: "操作",
    align: "center",
    width: 230,
    fixed: 'right',
    // customRender: (text, record, index)=> fuindex,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "FeedbackPage",
  components: {
    DrawerH5Preview,
    TopicSearchBar
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      feedbackColumns,
      h5PreviewVisiable: false,
      currentTopic: {},
      // 临时账号数据
      tempFeedbackData: {},
      listLoading: true,
      list: [],
      allRoles: [],
      searchParams: {
        search: "",
        status: undefined,
        extra_fields: 'backend_settings'
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 335;
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    // 编辑专题
    handleEditTopic(id) {
      this.$router.push({ name: "h5maker", query: { id } });
    },
    // 发布专题
    handlePubTopic(data) {
      const that = this;
      this.$confirm({
        title: "发布专题",
        content: () => (
          <div>
            确认发布专题 {data.page_title}？发布后用户将可正常访问该页面
          </div>
        ),
        async onOk() {
          const postData = {
            id: data.id,
            status: 1
          };
          const { err, res } = await patchTopic(postData);
          if (!err && res.success) {
            that.$message.success(res.message);
            that.getTopicList();
          }
        }
      });
    },
    handleGoTopicMaker(){
      this.$router.push({'name': 'h5maker'})
    },
    // 下线专题
    handleDownTopic(data) {
      const that = this;
      this.$confirm({
        title: "下线专题",
        content: () => (
          <div>确认下线专题 {data.page_title}？下线后用户将无法访问</div>
        ),
        async onOk() {
          const postData = {
            id: data.id,
            status: 2
          };
          const { err, res } = await patchTopic(postData);
          if (!err && res.success) {
            that.$message.success(res.message);
            that.getTopicList();
          }
        }
      });
    },
    // 获取意见反馈列表
    async getTopicList() {
      if (!checkPer("operate_manage_projects_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      // 搜索词去空格
      this.searchParams.search = this.searchParams.search.trim()
      const data = Object.assign(
        {
          page: this.pagination.current,
          page_size: this.pagination.pageSize
        },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getTopics(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList();
    },
    // 显示查看
    async handleShowTopicPreview(idx) {
      const topicData = this.list[idx];
      this.currentTopic = topicData;
      // const data = {
      //   type: "createPreview",
      //   title: topicData.page_title,
      //   apply_deadline_time: topicData.apply_deadline_time,
      //   items: topicData.backend_settings,
      //   id: topicData.id,
      //   uid: topicData.uid
      // };

      let apply_deadline_time = topicData.apply_deadline_time;
      if(apply_deadline_time){
        const timeArr = apply_deadline_time.split(":");
        apply_deadline_time = timeArr[0] + ":" + timeArr[1];
      }

      const data = {
        page_title: topicData.page_title,
        page_url: "",
        apply_fields: '[]',
        apply_deadline_time: apply_deadline_time,
        backend_settings: JSON.stringify(topicData.backend_settings),
        frontend_settings: "[]",
        id: topicData.id,
        uid: topicData.uid
      };
      const itemData = JSON.parse(JSON.stringify(topicData))
      itemData.isStartShowPreviewTopic = true;
      this.list.splice(idx, 1, itemData);
      const { err, res } = await previewTopic(data);
      itemData.isStartShowPreviewTopic = false;
      this.list.splice(idx, 1, itemData);
      if (!err) {
        if (res.success && res.data && res.data.uid) {
          this.previewUrl =
            "https://static.zheyi360.com/tempTopic/topic_" +
            res.data.uid +
            ".html?inPreview=1";
        }
      }

      this.h5PreviewVisiable = true;
    },
    // 搜索查询按钮
    topicSearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getTopicList();
    },
    // 隐藏用户信息drawer
    hideAccountModal(isNeedFresh = -1) {
      this.accountModalVisiable = false;
      if (isNeedFresh === 1) {
        this.pagination.current = 1;
        this.getTopicList();
      }
      if (isNeedFresh === 0) {
        this.getTopicList();
      }
    },
    // 隐藏用户详细信息drawer
    hideFeedbackModal() {
      this.h5PreviewVisiable = false;
    }
  }
};
</script>
<style lang='less' scoped>
  #specialManage{
   .ptb-10 {
     padding: 10px 0;
   }
   /deep/ .ant-table-body {
     //样式穿透
     overflow-x: auto !important;
   }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-10 {
  margin-right: 10px;
}
.ptb-10 {
  padding: 10px 0;
}
</style>
