<!--
 * @Author: 前胡
 * @LastEditors: 白青
 * @Date: 2020-03-05 16:44:54
 * @LastEditTime: 2020-05-26 16:02:43
 * @Description: 专题搜索条
 -->
<template>
  <div>
    <a-form class="filter-condition-form" layout="inline" :form="form" @submit="handleFormSubmit">
      <!-- <a-form-item>
        <a-select style="width: 120px" placeholder="搜索状态" v-model="searchParams.type">
          <a-select-option v-for="item in searchTypeOptions" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item> -->
      <a-form-item>
        <a-input
          v-model.trim="searchParams.search"
          allowClear
          placeholder="页面标题/专题ID"
        />
      </a-form-item>
      <a-form-item>
        <a-select style="width: 120px" placeholder="状态" v-model="searchParams.status" allowClear>
          <a-select-option v-for="item in topicStatusOptions" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
// 搜索类型
const searchTypeOptions = [
  { label: "商品名称", value: 2 },
  { label: "商品ID", value: 1 }
];

// 库存状态
const topicStatusOptions = [
  { label: "未发布", value: 0 },
  { label: "已发布", value: 1 },
  { label: "已下线", value: 2 },
];


export default {
  name: "TopicSearchBar",
  props: {
    searchParams: {
      default: function() {
        return {};
      },
      type: Object
    },
    loading: {
        default: false,
        type: Boolean
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      searchTypeOptions,
      topicStatusOptions,
    };
  },
  methods: {
    // 查询专题submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$emit('callback', this.searchParams)
        }
      });
    }
  }
};
</script>
<style lang='less' scoped>
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
</style>
