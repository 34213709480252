/*
 * @Description: 专题相关接口
 * @Date: 2019-12-23 14:59:46
 * @LastEditTime: 2020-05-29 17:24:36
 * @LastEditors: 前胡
 */
import axios from './axios'

// 管理后台专题列表
export const getTopics = params => {
  return axios.get('/api/operators/v1/projects/', {
    params
})
}

// 新建专题
export const createTopic = data => {
  return axios.post('/api/operators/v1/projects/', data)
}

// 预览专题
export const previewTopic = data => {
  // return axios.post('/topic', data)
  return axios.post('/api/operators/v1/projects-preview/', data)
}

// 更新专题
export const patchTopic = data => {
  return axios.patch('/api/operators/v1/projects/', data)
}

// 后台专题报名列表
export const getTopicApply = params => {
  return axios.get('/api/operators/v1/projects-apply/', {
    params
  })
}
